import React, { useState } from "react"
import './signDialog.css'

class SignDialog extends React.Component {
    DIALOG_TYPE_SIGNIN = 1
    DIALOG_TYPE_SIGNUP = 2
    DIALOG_TYPE_FORGOT_PASSWORD = 3

    constructor(props) {
        super(props)
        this.state = {
            dialogType: props.dialogType,
            prevDialogType: props.dialogType
        }
    }

    getTitle = (dialogType) => {
        switch (dialogType) {
            case this.DIALOG_TYPE_SIGNIN:
                return "Welcome Back!"
            case this.DIALOG_TYPE_SIGNUP:
                return "Sign Up For Free!"
            case this.DIALOG_TYPE_FORGOT_PASSWORD:
            default:
                return "Forgot Password?"
        }
    }

    getDescription = (dialogType) => {
        switch (dialogType) {
            case this.DIALOG_TYPE_SIGNIN:
                return "Surf more, Learn more"
            case this.DIALOG_TYPE_SIGNUP:
                return "Join with us for expand your knowledge"
            case this.DIALOG_TYPE_FORGOT_PASSWORD:
            default:
                return "Please enter your email address and we will mail you a link to reset your password"
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.dialogType != state.prevDialogType) { // State updated
            return {
                dialogType: state.dialogType,
                prevDialogType: state.dialogType
            }
        } else if (props.dialogType != state.prevDialogType) { // Props updated
            return {
                dialogType: props.dialogType,
                prevDialogType: props.dialogType
            }
        }
        return null
    }

    render() {
        return (
            <div className="modal fade" id="signDialog" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="container">
                                <div className="row row-cols-1">
                                    <div className="col">
                                        <h1 className="modal-title" id="exampleModalLabel">{this.getTitle(this.state.dialogType)}</h1>
                                    </div>
                                    <div className="col">
                                        <p>{this.getDescription(this.state.dialogType)}</p>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <nav className={this.state.dialogType != this.DIALOG_TYPE_FORGOT_PASSWORD ? "" : "d-none"}>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">

                                    <button className={"nav-link " + (this.state.dialogType == this.DIALOG_TYPE_SIGNUP ? 'active' : '')} id="nav-signUp-tab"
                                        data-bs-toggle="tab" data-bs-target="#nav-signUp" type="button" role="tab" aria-controls="nav-signUp"
                                        aria-selected="true" onClick={() => this.setState({ dialogType: this.DIALOG_TYPE_SIGNUP })}>Sign Up</button>

                                    <button className={"nav-link " + (this.state.dialogType == this.DIALOG_TYPE_SIGNIN ? 'active' : '')} id="nav-signIn-tab"
                                        data-bs-toggle="tab" data-bs-target="#nav-signIn" type="button" role="tab" aria-controls="nav-signIn"
                                        aria-selected="false" onClick={() => this.setState({ dialogType: this.DIALOG_TYPE_SIGNIN })}>Sign In</button>

                                </div>
                            </nav>

                            <div className={"tab-content " + (this.state.dialogType != this.DIALOG_TYPE_FORGOT_PASSWORD ? "" : "d-none")} id="nav-tabContent">
                                <div className={"tab-pane fade " + (this.state.dialogType == this.DIALOG_TYPE_SIGNUP ? 'active show' : '')} id="nav-signUp"
                                    role="tabpanel" aria-labelledby="nav-signUp-tab">
                                    {/* Form Sign Up*/}
                                    <div className="form-input col-lg-5">
                                        <form>
                                            <div className="row gx-5">
                                                {/* First Name */}
                                                <div className="col">
                                                    <input type="text" placeholder="First Name" className="form-control inputTag"
                                                        id="inputFirstname" />
                                                </div>
                                            </div>

                                            <div className="w-100"></div>

                                            <div className="row gx-5">
                                                {/* Last Name */}
                                                <div className="col">
                                                    <input type="text" placeholder="Last Name" className="form-control inputTag" id="inputLastname" />
                                                </div>
                                            </div>

                                            <div className="w-100"></div>

                                            <div className="row gx-5">
                                                {/* Email */}
                                                <div className="col">
                                                    <input type="email" placeholder="Email" className="form-control inputTag" id="inputPassword4" />
                                                </div>
                                            </div>

                                            <div className="w-100"></div>

                                            <div className="row gx-5">
                                                {/* Password */}
                                                <div className="col">
                                                    <input type="password" placeholder="Password" className="form-control inputTag" id="inputPassword5" />
                                                </div>
                                            </div>

                                            <div className="w-100"></div>

                                            {/* Check Box */}
                                            <div className="form-check form-check-inline checkbox-terms">
                                                <input className="form-check-input" type="checkbox" id="checkbox-terms" value="option1" />
                                                <label className="form-check-label" htmlFor="checkbox-terms">
                                                    I Agree to the <a href="#" className="terms-a">terms and Conditions</a>
                                                </label>
                                            </div>

                                            <div className="w-100"></div>

                                            {/* Button Sign Up */}
                                            <div className="col">
                                                <button type="submit" className="btn btn-apply">Sign Up</button>
                                            </div>
                                            <div className="w-100"></div>
                                        </form>
                                    </div>
                                    {/* Divider */}
                                    <div className="col-lg-2 center-design">
                                        <div className="vertical-line1"></div>
                                        <div className="center-wrap">
                                            <p>or</p>
                                        </div>
                                        <div className="vertical-line2"></div>
                                    </div>

                                    {/* social*/}
                                    <div className="social col-lg-4 align-items-center">
                                        <div className="row g-0 align-items-center social-wrap">
                                            <div className="col-sm-1 col-md-1 google-account">
                                                <img src="./img/google.png" alt="Google" />
                                            </div>
                                            <div className="col">
                                                <p>Sign up With Google</p>
                                            </div>
                                        </div>
                                        <div className="row g-0 align-items-center social-wrap">
                                            <div className="col-sm-1 col-md-1 google-account">
                                                <img src="./img/facebook.png" alt="Google" />
                                            </div>
                                            <div className="col">
                                                <p>Sign up With Facebook</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"tab-pane fade " + (this.state.dialogType == this.DIALOG_TYPE_SIGNIN ? 'active show' : '')} id="nav-signIn"
                                    role="tabpanel" aria-labelledby="nav-signIn-tab">
                                    {/* Form Sign In*/}
                                    <div className="form-input col-5">
                                        <form>
                                            <div className="row gx-5">
                                                {/* Email */}
                                                <div className="col">
                                                    <input type="email" placeholder="Email" className="form-control inputTag" id="inputPassword4" />
                                                </div>
                                            </div>

                                            <div className="w-100"></div>

                                            <div className="row gx-5">
                                                {/* Password */}
                                                <div className="col">
                                                    <input type="password" placeholder="Password" className="form-control inputTag" id="inputPassword5" />
                                                </div>
                                            </div>
                                            {/* Check Box */}
                                            <div className="form-check form-check-inline checkbox-keep-logging">
                                                <input className="form-check-input" type="checkbox" id="checkbox-keep-logging" value="option1" />
                                                <label className="form-check-label" htmlFor="checkbox-keep-logging">
                                                    Keep me logged in
                                                </label>
                                            </div>
                                            <div className="row gx-5">
                                                {/* Button Sign In */}
                                                <div className="col">
                                                    <button type="submit" className="btn btn-apply">Sign In</button>
                                                </div>
                                            </div>
                                            <div className="forgot-password">
                                                <a className="aTag" onClick={() => this.setState({ dialogType: this.DIALOG_TYPE_FORGOT_PASSWORD })}>Forgot Password?</a>
                                            </div>
                                        </form>
                                    </div>
                                    {/* Divider */}
                                    <div className="col-2 center-design">
                                        <div className="vertical-line1"></div>
                                        <div className="center-wrap">
                                            <p>or</p>
                                        </div>
                                        <div className="vertical-line2"></div>
                                    </div>

                                    {/* social*/}
                                    <div className="social col-4 align-items-center">
                                        <div className="row g-0 align-items-center social-wrap">
                                            <div className="col-sm-1 col-md-1 google-account">
                                                <img src="./img/google.png" alt="Google" />
                                            </div>
                                            <div className="col">
                                                <p>Sign in With Google</p>
                                            </div>
                                        </div>
                                        <div className="row g-0 align-items-center social-wrap">
                                            <div className="col-sm-1 col-md-1 google-account">
                                                <img src="./img/facebook.png" alt="Google" />
                                            </div>
                                            <div className="col">
                                                <p>Sign in With Facebook</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Reset Password */}
                            <div className={"col btn-reset " + (this.state.dialogType != this.DIALOG_TYPE_FORGOT_PASSWORD ? "d-none" : "")}>
                                <div className="form-input col-11">
                                    <form>
                                        <div className="row">
                                            {/* Email */}
                                            <div className="col">
                                                <input type="email" placeholder="Email Address" className="form-control inputTag" id="inputPassword4" />
                                            </div>
                                        </div>

                                        <div className="w-100"></div>

                                        <div className="row">
                                            {/* Button Sign In */}
                                            <div className="col">
                                                <button type="submit" className="btn btn-apply">Reset my password</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class={"modal-footer " + (this.state.dialogType != this.DIALOG_TYPE_FORGOT_PASSWORD ? "d-none" : "")}>
                            <a className="footer-a" onClick={() => this.setState({ dialogType: this.DIALOG_TYPE_SIGNIN })}>Back to Login</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignDialog