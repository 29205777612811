import React from "react";
import "./whatWeOffer.css";

const WhatWeOffer = (props) => {
  return (
    <div className="container-fluid">
      <div className="container" id="offer">
        <div className="row">
          <div className="col-lg-7 col-md-12 offer-wrap">
            <h1>What We Do</h1>
            <p>
              At OTBS, we revolutionize supply chain management with tailored
              solutions. Drawing on over a decade of expertise, we streamline
              logistics, enhance efficiency, and drive global business success
              uniquely for you.
            </p>

            <div className="tag">
              <div className="icon">
                <i className="fas fa-cog"></i>
              </div>
              <div className="p-3 description">
                <h4>Supply Chain Optimization</h4>
                <p>
                  Streamlining logistics, import/export, and warehouse
                  management for efficient business operations.
                </p>
              </div>
            </div>

            <div className="tag">
              <div className="icon">
                <i className="fas fa-user-graduate"></i>
              </div>
              <div className="p-3 description">
                <h4>Business Registrations</h4>
                <p>
                  Simplifying company registration, regulatory compliance, and
                  approvals for seamless business setup.
                </p>
              </div>
            </div>

            <div className="tag">
              <div className="icon">
                <i className="fas fa-chalkboard-teacher"></i>
              </div>
              <div className="p-3 description">
                <h4>BOI Procedure Expertise</h4>
                <p>
                  Expertly guiding businesses through the intricacies of Board
                  of Investment (BOI) procedures in Sri Lanka.
                </p>
              </div>
            </div>

            <div className="tag">
              <div className="icon">
                <i className="far fa-building"></i>
              </div>
              <div className="p-3 description">
                <h4>Social Media Management</h4>
                <p>
                  Optimizing online presence through strategic management of
                  various social media platforms for maximum impact.
                </p>
              </div>
            </div>

            <div className="tag">
              <div className="icon">
                <i className="fas fa-book-open"></i>
              </div>
              <div className="p-3 description">
                <h4>Customized Solutions</h4>
                <p>
                  Tailoring services to fit the unique needs and goals of
                  businesses, whether mid-level, start-up, or corporation.
                </p>
              </div>
            </div>

            <div className="tag">
              <div className="icon">
                <i className="fas fa-dumbbell"></i>
              </div>
              <div className="p-3 description">
                <h4>Global Trade Facilitation</h4>
                <p>
                  Assisting businesses in navigating international markets by
                  providing guidance on import/export processes and regulatory
                  compliance.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 image d-none d-lg-block"
            style={{ backgroundImage: "url(img/image3.png)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
