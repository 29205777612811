import React from "react";
import "./faq.css";

const FAQ = (props) => {
  const faqDataList = [
    {
      question: "How to Register a Company in Sri Lanka?",
      answer:
        "Ometa Total Business Solutions is your ultimate partner for seamless company registration. Don't waste precious time and money on re-registering your business. We've made it easier than ever. Confused by the challenging process of business registration? We're here to simplify it for you. Our mission is to deliver affordable, top-notch services to streamline your company incorporation and assist with all your documentation needs. Company registration isn't just a formality; it's a legal pathway to establish your business in compliance with the Companies Act No. 7 of 2007. Meanwhile, foreign entrepreneurs can also register their businesses in Sri Lanka with our guidance.",
    },
    {
      question:
        "How to get Approval from Telecommunication Regulatory Commission in Sri Lanka?",
      answer:
        "Every importer in Sri Lanka is bound by law to secure TRCSL Type Approval under their name before importing Radio Telecommunication Equipment. Additionally, every approval endeavor requires a mandatory sample submission. Ometa Total Business Solutions is at the forefront expertly navigating this regulatory landscape, ensuring approval for the equipment categories that require it. We've got you covered.",
    },
    {
      question: "How to Register a Company with Sri Lanka Customs?",
      answer:
        "Whether you're a local business owner eyeing international markets or an aspiring entrepreneur eager to import and serve your local clientele, one thing's for sure, you'll need to partner with the Sri Lanka Customs Department to make it happen. We are here to guide you through the process, every step of the way.",
    },
    {
      question:
        "How to Register a Company with the Department of Import & Export?",
      answer:
        "Are you planning to set up an import or export business in Sri Lanka? Sri Lanka’s unique geographic location offers many advantages to operate an import or export business within the country. However, understanding the processes and regulations can be a challenge without the proper guidance. We at OTBS will navigate you through this process and ease your burden to get registered with Department of Import & Export.",
    },
    {
      question: "How to Register your Company with the Department of Commerce?",
      answer:
        "Exporters who wish to utilize free and preferential Trade Agreements must register their company with the Department of Commerce (DoC). This registration is a one-time requirement, such as when obtaining a certificate of origin. For a streamlined experience, OTBS will assist you in the step-by-step registration process.",
    },
    {
      question: "How to Register as a Ceylon Tea Supplier of EDB?",
      answer:
        "Do you plan to become a Ceylon Tea exporter in Sri Lanka? Register with the Sri Lanka Export Development Board (EDB) and connect with the global supply chain and international buyers. Follow our simple process to register your company as an exporter with EDB Sri Lanka and receive a wide range of benefits.",
    },
  ];

  const getIDs = (type, i) => {
    if (type === "accordion-item-id") {
      return "flush-heading" + i;
    } else if (type === "data-bs-target") {
      return "#flush-collapse" + i;
    } else if (type === "aria-controls") {
      if (i === 1) {
        return "flush-collapse" + i;
      } else {
        return "flush-collapse" + (i - 1);
      }
    } else if (type === "flush-collapse-id") {
      return "flush-collapse" + i;
    } else if (type === "aria-labelledby") {
      return "flush-heading" + i;
    }
  };

  const generateFAQs = () => {
    let faqList = [];
    let i = 0;
    faqDataList.forEach((element) => {
      i++;
      faqList.push(
        <div className="accordion-item">
          <h2 className="accordion-header" id={getIDs("accordion-item-id", i)}>
            <button
              className="accordion-button collapsed collapsed-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={getIDs("data-bs-target", i)}
              aria-expanded="false"
              aria-controls={getIDs("aria-controls", i)}
            >
              {element.question}
            </button>
          </h2>
          <div
            id={getIDs("flush-collapse-id", i)}
            className="accordion-collapse collapse"
            aria-labelledby={getIDs("aria-labelledby", i)}
            data-bs-parent="#accordionFlush"
          >
            <div className="accordion-body">{element.answer}</div>
          </div>
        </div>
      );
    });
    return faqList;
  };

  return (
    <div className="pt-100">
      <div className="container">
        <div className="row">
          {/* Accordion */}
          <div className="modules">
            <div className="module-title">
              <h3>Frequently Asked Questions</h3>
            </div>
            <div className="accordion" id="accordionFlush">
              {generateFAQs()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
