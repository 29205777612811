import React, { useState } from "react";
import SignDialog from "./../signDialog/signDialog";
import "./header.css";

class Header extends React.Component {
  DIALOG_TYPE_SIGNIN = 1;
  DIALOG_TYPE_SIGNUP = 2;
  DIALOG_TYPE_FORGOT_PASSWORD = 3;

  constructor(props) {
    super(props);

    this.state = { dialogType: this.DIALOG_TYPE_SIGNIN };
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container">
          <div className="row" id="header">
            <div className="col logo">
              <a href="/home">
                <img src="./img/logoIcon.svg" alt="Ometa Business Solutions" />
              </a>
            </div>
            <div className="col d-none d-lg-block call"></div>
            <div className="col d-none d-sm-block email">
              <div>
                <i className="fab fa-telegram-plane fa-2x"></i>
              </div>
              <div>
                <h4>Email</h4>
                <p>
                  <a href="#">info@otbs.lk</a>
                </p>
              </div>
            </div>
            <div className="col d-none d-lg-block call">
              <div>
                <i className="fas fa-phone-alt fa-2x"></i>
              </div>
              <div>
                <h4>Call</h4>
                <p>
                  Call Us:
                  <a href="#"> +94 76 868 5588</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
