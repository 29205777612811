import React from "react";
import "./whatWeDo.css";

const Services = (props) => {
  const serviceList = [
    {
      name: "Logistics & Transportation",
      description:
        "Streamlined logistics solutions for efficient movement of cargo, ensuring seamless import/export processes.",
      tileImage: "img/image1.jpg",
      viewMore: "http://link.to.main.article/1",
    },
    {
      name: "BOI Procedure Assistance",
      description:
        "Expert guidance in navigating Board of Investment (BOI) procedures, simplifying complexities for businesses in Sri Lanka.",
      tileImage: "img/image2.jpg",
      viewMore: "http://link.to.main.article/2",
    },
    {
      name: "General Cargo Management",
      description:
        "Oversight of general cargo, ensuring safe and timely delivery with customized logistics solutions to meet specific needs.",
      tileImage: "img/image3.jpg",
      viewMore: "http://link.to.main.article/3",
    },
    {
      name: "Warehouse & Inventory Services",
      description:
        "Trusted partner for both inbound and outbound operations, offering specialized warehouse and inventory management services.",
      tileImage: "img/image4.jpg",
      viewMore: "http://link.to.main.article/4",
    },
    {
      name: "Business Registration in Sri Lanka",
      description:
        "Simplifying the process of company registration in Sri Lanka, ensuring compliance with legal requirements.",
      tileImage: "img/image5.jpg",
      viewMore: "http://link.to.main.article/5",
    },
    {
      name: "TRCSL Type Approval Support",
      description:
        "Assistance in securing Telecommunication Regulatory Commission Type Approval for radio telecommunication equipment import.",
      tileImage: "img/image6.jpg",
      viewMore: "http://link.to.main.article/6",
    },
    {
      name: "Sri Lanka Customs Registration",
      description:
        "Guiding businesses through the registration process with the Sri Lanka Customs Department for international trade operations.",
      tileImage: "img/image7.jpg",
      viewMore: "http://link.to.main.article/7",
    },
    {
      name: "Department of Import & Export Registration",
      description:
        "Step-by-step assistance for businesses setting up import/export operations, ensuring compliance with regulatory requirements.",
      tileImage: "img/image8.jpg",
      viewMore: "http://link.to.main.article/8",
    },
    {
      name: "Department of Commerce Registration",
      description:
        "Facilitating registration with the Department of Commerce for businesses utilizing free and preferential trade agreements.",
      tileImage: "img/image9.jpg",
      viewMore: "http://link.to.main.article/9",
    },
    {
      name: "Ceylon Tea Supplier Registration with EDB",
      description:
        "Guidance for becoming a registered Ceylon Tea exporter with the Sri Lanka Export Development Board (EDB).",
      tileImage: "img/image10.jpg",
      viewMore: "http://link.to.main.article/10",
    },
    {
      name: "Social Media Management",
      description:
        "Strategic management of social media channels to optimize online presence for businesses, entrepreneurs, and companies.",
      tileImage: "img/image11.jpg",
      viewMore: "http://link.to.main.article/11",
    },
    {
      name: "Global Trade Facilitation",
      description:
        "Assisting businesses in navigating international markets by providing guidance on import/export processes and regulatory compliance.",
      tileImage: "img/image12.jpg",
      viewMore: "http://link.to.main.article/12",
    },
    {
      name: "Customized Business Solutions",
      description:
        "Tailored services to meet specific business needs, offering flexibility and expertise to address unique challenges and requirements.",
      tileImage: "img/image13.jpg",
      viewMore: "http://link.to.main.article/13",
    },
  ];

  const generateTiles = () => {
    let tileList = [];
    let j = 0;
    serviceList.forEach((element) => {
      tileList.push(
        <div className="col-lg-4 col-md-6 col-sm-6">
          <div className="single-cat text-center mb-50">
            <div className="cat-icon">
              <span className=""></span>
            </div>
            <div className="cat-cap">
              <h5>{element.name}</h5>
              <p>{element.description}</p>
            </div>
          </div>
        </div>
      );
    });
    return tileList;
  };

  return (
    <div className="categories-area pt-100">
      <div className="container">
        <div className="row">{generateTiles()}</div>
      </div>
    </div>
  );
};

export default Services;
