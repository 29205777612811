import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./institute/index";
import Contact from "./institute/contactUs";
import AboutUs from "./institute/aboutUs";
import WhatWeDo from "./institute/whatWeDo";
import FAQ from "./institute/faq";

const OTBSRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/home" component={Home} />
        <Route path="/what-we-do" component={WhatWeDo} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact" component={Contact} />
        <Route path="/faq" component={FAQ} />
      </Switch>
    </Router>
  );
};

export default OTBSRouter;
