import React from "react"
import './pageTitle.css'

const pageTitle = (props) => {
    return (
        <div className="container-fluid path" style={{ backgroundImage: 'url(img/banner.png)' }}>
            <div className="row gradient">
                <div className="col">
                    <div className="text-wrap">
                        <h1>{props.title}</h1>
                        <div className="breadcrumb">
                            {
                                props.breadcrumbSteps.map(element => 
                                    <div>
                                        <a href="#">{element}</a>
                                        <i className="fas fa-angle-right fa-1x"></i>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default pageTitle