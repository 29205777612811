import React from "react"
import Header from "./components/header/header"
import NavBar from "./components/navBar/navBar"
import Footer from "./components/footer/footer"
import ContactUs from "./components/contactUs/contactUs"
import PageTitle from "./components/pageTitle/pageTitle"

const InstituteContactUs = () => {
    return (
        <div>
            <Header />
            <NavBar />
            <PageTitle title={"Contact Us"} breadcrumbSteps={["Home", "Contact"]}/>
            <ContactUs />
            <Footer />
        </div>
    )
}

export default InstituteContactUs