import React from "react";
import "./aboutUs.css";
//import chip
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

const CourseInfo = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    roots: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }));

  const classes = useStyles();
  const [chipData] = React.useState([
    { key: 0, label: "IT" },
    { key: 1, label: "Designing" },
    { key: 2, label: "Graphic" },
  ]);

  return (
    <div className="container-fluid courseInfo">
      <div className="container">
        <div className="title-wrapper">
          <h1 className="title">
            Unveiling Ometa Total Business Solutions (OTBS)
          </h1>
          <p className="subtitle">Be part of simplify</p>
        </div>
        <div className="row course-description">
          <div className="col">
            <img
              src="img/image 24.jpg"
              className="col-lg-5 col-md-4 img-fluid float-start"
              alt="Image"
            />
            <p>
              Welcome to Ometa Total Business Solutions (OTBS), a dynamic force
              in the realm of business solutions, revolutionizing the landscape
              of supply chain management since our inception in 2017. As a proud
              subsidiary of Ometa Management Private Limited, established in
              2010, our trajectory is marked by a steadfast commitment to
              providing holistic services that propel businesses toward growth
              and development. At OTBS, we stand as more than just service
              providers; we are your dedicated partners, navigating the
              intricacies of modern business to ensure your success at every
              turn.
            </p>
            <p>
              Our foundation is built on a profound understanding of the complex
              and dynamic nature of contemporary businesses. With a decade of
              experience, we have honed our expertise in supply chain
              management, offering a comprehensive suite of services that cover
              logistics, import/export, warehouse management, and BOI (Board of
              Investment) procedures. Our mission is to streamline your
              operations, reduce costs, and enhance overall productivity,
              providing you with a competitive advantage in the global market.
              Whether you are a mid-level company, startup, or corporation, our
              solutions are designed to fit your specific business needs and
              goals.
            </p>
            <p>
              What sets us apart is our personalized approach. We recognize that
              every business is unique, and, therefore, we tailor our services
              to align with your individual requirements. Whether you are
              seeking assistance in business registration, regulatory
              compliance, or social media management, our team is dedicated to
              understanding your distinct needs and delivering solutions that
              drive success. Our focus extends beyond mere service provision; we
              are committed to being the cornerstone of your success, ensuring
              that every aspect of your business operation is in capable and
              reliable hands.
            </p>
            <p>
              At OTBS, we pride ourselves not only on our technical expertise
              but also on our unwavering commitment to integrity and compliance.
              Upholding the highest standards, we offer transparent and reliable
              operations to instill confidence in every partnership. Our client
              testimonials speak volumes about the tangible results and cost
              savings we have delivered over the years. Join us in reshaping the
              future of supply chain management, as we work collaboratively to
              empower your business to reach its fullest potential. Welcome to a
              partnership defined by innovation, dedication, and success -
              welcome to Ometa Total Business Solutions.
            </p>
          </div>
        </div>

        {/* Our Vision */}
        <div className="modules">
          <div className="module-title">
            <h3>Our Vision</h3>
          </div>
          <div className="row course-description">
            <div className="col">
              <p className="quote">
                "At Ometa Total Business Solutions (OTBS), our vision is to
                emerge as the global leader in reshaping the future of supply
                chain management. We aspire to be the go-to partner for
                businesses worldwide, guiding them toward sustainable growth,
                operational excellence, and enduring success in an ever-evolving
                business landscape. Our commitment to innovation, client-centric
                solutions, and continuous adaptation to industry trends
                positions us to lead the charge in redefining the benchmarks of
                excellence within the supply chain management domain."
              </p>
            </div>
          </div>
        </div>

        {/* Our Mission */}
        <div className="modules">
          <div className="module-title">
            <h3>Our Mission</h3>
          </div>
          <div className="row course-description">
            <div className="col">
              <p className="quote">
                "Our mission at OTBS is clear and resolute: to empower
                businesses to reach their fullest potential. We are dedicated to
                providing innovative and tailored solutions that enhance
                efficiency, reduce costs, and foster growth. With a focus on
                integrity, transparency, and client satisfaction, we strive to
                be the cornerstone of success for our clients. From streamlining
                logistics to simplifying regulatory procedures, our mission is
                to be your trusted partner, ensuring every aspect of your
                business operation is in capable and reliable hands."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
