import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { Link } from "react-router-dom";
import "./carousel.css";

class Carousel extends React.Component {
  bannerList = [
    {
      index: 1,
      title: "Empowering Your Business Journey",
      description:
        "Your Trusted Partner in Business Excellence, Providing Tailored Solutions to Elevate, Simplify, and Safeguard Your Journey to Success.",
      image: "img/banner1.jpg",
      button: {
        availability: true,
        content: "Read More",
        url: "/about-us",
      },
    },
    {
      index: 2,
      title: "Navigating Success Together",
      description:
        "Partnering for Success with Expert Guidance, Seamless Growth Strategies, and Custom Solutions Meticulously Aligned with Your Unique Goals and Ambitions.",
      image: "img/banner2.jpg",
      button: {
        availability: false,
        content: "Contact Us",
      },
    },
    {
      index: 3,
      title: "A Decade of Winning Together - Your Path to Success",
      description:
        "Simple Solutions, Global Expertise, Unwavering Integrity, and Cost-Efficiency",
      image: "img/banner3.jpg",
      button: {
        availability: true,
        content: "What we do",
        url: "/what-we-do",
      },
    },
  ];

  render() {
    return (
      <Slider autoplay={4000} className="carousel">
        {this.bannerList.map((element) => (
          <div
            key={element.index}
            className="sliderContent"
            style={{
              background: `url('${element.image}') no-repeat center center`,
            }}
          >
            <div className="inner">
              <h1>{element.title}</h1>
              <p>{element.description}</p>
              {element.button.availability ? (
                <Link to={element.button.url} className="btn">
                  {element.button.content}
                </Link>
              ) : null}
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

export default Carousel;
