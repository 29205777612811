import React from "react";
import Header from "./components/header/header";
import NavBar from "./components/navBar/navBar";
import Carousel from "./components/carousel/carousel";
import Categories from "./components/categories/categories";
import WhatWeOffer from "./components/whatWeOffer/whatWeOffer";
import IntroVideo from "./components/introVideo/introVideo";
import Testiamonials from "./components/testiamonials/testimonials";
import Footer from "./components/footer/footer";

const InstituteIndex = () => {
  return (
    <div>
      <Header />
      <NavBar />
      <Carousel />
      <Categories />
      <WhatWeOffer />
      <IntroVideo />
      <Testiamonials />
      <Footer />
    </div>
  );
};

export default InstituteIndex;
