import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "./testimonials.css";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const Testimonials = (props) => {
  return (
    <div className="container-fluid testimonials">
      <div className="container">
        <div className="row col align-self-center justify-content-center ">
          <div className="col-9 align-self-center justify-content-center headder-div">
            <h1>Voices of Success</h1>
            <p>
              Dive into the success stories of our valued clients. Discover how
              OTBS has empowered businesses and fueled growth through
              personalized solutions. Your success story could be next!
            </p>
          </div>
          <div className="w-100"></div>
          <div className="slider-wrapper">
            <i className="fas fa-quote-left quote"></i>
            <>
              <Swiper pagination={true} navigation={true} className="slider">
                <SwiperSlide className="slide">
                  <div className="row justify-content-center slide-wrap">
                    <div className="col-5 col-sm-3 col-md-3 col-lg-2 slide-img">
                      <img src="img/image 18.jpg" className="img-fluid" />
                    </div>
                    <div className="align-self-center float-end col-10 col-sm-8 col-md-6 col-lg-5 slide-content">
                      <p className="message">
                        OTBS transformed our supply chain management. Their
                        expertise and personalized approach streamlined our
                        operations, reducing costs and boosting efficiency. A
                        truly invaluable partner for business success!
                      </p>
                      <h6 className="name">Akila Dayananda</h6>
                      <p className="position">Managing Director</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                  <div className="row justify-content-center slide-wrap">
                    <div className="col-5 col-sm-3 col-md-3 col-lg-2 slide-img">
                      <img src="img/image 19.jpg" className="img-fluid" />
                    </div>
                    <div className="align-self-center float-end col-10 col-sm-8 col-md-6 col-lg-5 slide-content">
                      <p className="message">
                        Exceptional service! OTBS made business registration a
                        breeze. Their team's guidance and efficiency exceeded
                        our expectations. Grateful for their support in every
                        step of our journey
                      </p>
                      <h6 className="name">Jane Smith</h6>
                      <p className="position">Founder of ABC Ventures</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="slide">
                  <div className="row justify-content-center slide-wrap">
                    <div className="col-5 col-sm-3 col-md-3 col-lg-2 slide-img">
                      <img src="img/image 19.jpg" className="img-fluid" />
                    </div>
                    <div className="align-self-center float-end col-10 col-sm-8 col-md-6 col-lg-5 slide-content">
                      <p className="message">
                        OTBS's social media management catapulted our online
                        presence. Their strategic approach significantly
                        enhanced our brand visibility, connecting us with our
                        target audience. Highly recommended for digital success!
                      </p>
                      <h6 className="name">David Johnson</h6>
                      <p className="position">E-commerce Entrepreneur</p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
