import React from "react";
import "./categories.css";

class Categories extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row" id="category-boxes">
          <div className="col-6 col-sm-3 redbox">
            <img
              src="/img/supply-chain-management 1.svg"
              alt="Supply Chain Management"
            />
            <h3>Supply Chain Management</h3>
            <p className="d-none d-md-block">
              Streamline logistics, cargo handling, and BOI procedures for
              efficient, cost-effective business operations.
            </p>
          </div>
          <div className="col-6 col-sm-3 bluebox">
            <img
              src="/img/business-registration.svg"
              alt="Business Registrations"
            />
            <h3>Business Registrations</h3>
            <p className="d-none d-md-block">
              Simplify company registration, approvals, and compliance processes
              with expert guidance for seamless business setup.
            </p>
          </div>
          <div className="col-6 col-sm-3 redbox">
            <img
              src="/img/social-media-management.svg"
              alt="Social medial management"
            />
            <h3>Social Media Management</h3>
            <p className="d-none d-md-block">
              Optimize your online presence with our trusted social media
              management for maximum impact and success.
            </p>
          </div>
          <div className="col-6 col-sm-3 bluebox">
            <img
              src="/img/regulatory-compliance.svg"
              alt="Regulatory Compliance"
            />
            <h3>Regulatory Compliance</h3>
            <p className="d-none d-md-block">
              Navigate regulatory landscapes effortlessly with our services,
              ensuring compliance and smooth business operations.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Categories;
