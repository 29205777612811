import React from "react";
import "./introVideo.css";

const IntroVideo = (props) => {
  return (
    <div
      className="intro-video"
      style={{ backgroundImage: "url(/img/image5.png)" }}
    >
      <div className="container-fluid gradient">
        <div className="container">
          <div className="row">
            <h1 className="title d-sm-block d-md-none">
              Ometa Total Business Solutions
            </h1>
            <div className="col-md-6 col-sm-12 video">
              <div
                className="ratio"
                style={{ "--bs-aspect-ratio": "66.6667%" }}
              >
                <video muted loop autoplay="">
                  <source src="/img/intro.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col text">
              <h1 className="mt-0 title d-none d-md-block">
                Ometa Total Business Solutions
              </h1>
              <p>
                Welcome to Ometa Total Business Solutions (OTBS), your dedicated
                partner in navigating the complexities of modern business.
                Established in 2017 as a proud subsidiary of Ometa Management
                Private Limited, we specialize in revolutionizing supply chain
                management. With over a decade of experience, we offer
                comprehensive solutions, from logistics and business
                registration to BOI procedure expertise. Our commitment is to
                streamline your operations, reduce costs, and enhance
                productivity, ensuring your success in a dynamic global market.
                Trust OTBS to be your guide, providing tailored services that
                align with your business needs and goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroVideo;
