import React from "react";
import "./contactUs.css";

const ContactUs = (props) => {
  return (
    <div className="contactus">
      <div className="container-fluid">
        <div className="container">
          <div className="row gx-sm-2 gx-md-3 gx-lg-4 gx-xl-5 contact-details">
            <div className="col-sm-4">
              <div>
                <h3>Address</h3>
                <p>
                  IBM Building, 3rd Floor, 48, Nawam Mawatha, Colombo 2, Sri
                  Lanka
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div>
                <h3>Contact Number</h3>
                <p>+94 76 868 5588</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div>
                <h3>Email Address</h3>
                <p>info@otbs.lk</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="map">
        <div className="gmap_canvas">
          <iframe
            title="map"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=IBM,+Navam+Mawatha,+Colombo,+Sri+Lanka&t=&z=18&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </div>

      <div className="container-fluid message">
        <div className="container">
          <div className="row">
            <div className="col contact-wrap">
              <div className="col-12 col-sm-10 col-md-7 col-lg-6 col-xl-5">
                <h1>Contact Us</h1>
              </div>
              <div className="tag">
                <form>
                  <div className="row gx-5">
                    {/* Name */}
                    <div className="col-xl-6 col-lg-8 col-md-10">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        id="inputFirstname"
                      />
                    </div>
                  </div>

                  {/* Email */}
                  <div className="row gx-5">
                    <div className="col-xl-6 col-lg-8 col-md-10">
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        id="inputLastname"
                      />
                    </div>
                  </div>

                  <div className="row gx-5">
                    {/* Subject */}
                    <div className="col-xl-6 col-lg-8 col-md-10">
                      <input
                        type="text"
                        placeholder="Subject"
                        className="form-control"
                        id="inputPhone"
                      />
                    </div>
                  </div>

                  <div className="row gx-5">
                    {/* Message */}
                    <div className="col-xl-6 col-lg-8 col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        id="message"
                        placeholder="Message"
                      />
                    </div>
                  </div>

                  <div className="row gx-5">
                    {/* Button Send Message */}
                    <div className="col-12">
                      <button type="submit" className="btn btn-apply">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
